/* eslint-disable */
import * as React from 'react';
import LinkIcon from '@mui/icons-material/Link';
import MicIcon from '@mui/icons-material/Mic';

import {useState, useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import "./graph.css";
import Grid from '@mui/material/Grid';
import { TextField }from '@mui/material';
import { Box }from '@mui/material';
import { Button }from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import PermalinkModal from './PermalinkModal.js';

import { getFrequency, huffmanEncode, getRandomQuote } from "./huffman";
import * as d3 from "d3";
import dagreD3 from 'dagre-d3';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

function Graph(props) {

  const [zoomTransform, setZoomTransform] = useState(null);

  useEffect(() => {

    var g = new dagreD3.graphlib.Graph()
      .setGraph({})
      .setDefaultEdgeLabel(function() { return {}; });

    const frequency = getFrequency(props.text.toUpperCase());
    const encodedGraphRoot = huffmanEncode(frequency);

    const recurseGraph = (node) => {
      if (node.isLeaf()) {
        const leafLabel = node.getName() + "   (" + node.getPriority() + ")\n \n" + node.getCode();
        g.setNode(node.getName(), {label: leafLabel });
      } else {
        g.setNode(node.getName(), {label: node.getPriority(), shape: "circle", class: "circle"});
        if (node.L !== null) {
          recurseGraph(node.L)
          g.setEdge(node.getName(), node.L.getName(), {label: "L = 0", class: "leftedge"})
        }
        if (node.R !== null) {
          recurseGraph(node.R)
          g.setEdge(node.getName(), node.R.getName(), {label: "R = 1", class: "rightedge"})
        }
      }
    }

    if (encodedGraphRoot !== undefined) {
      recurseGraph(encodedGraphRoot);
    }

    var render = new dagreD3.render();

    // Set up an SVG group so that we can translate the final graph.
    var svg = d3.select("#graph");
    svg.attr("style", "outline: solid black; width: 100%")
    svg.selectAll("g").remove();
    var svgGroup = svg.append("g");

    var zoom = d3.zoom().on("zoom", function(event) {
        setZoomTransform(event.transform);
        svgGroup.attr("transform", event.transform);
      });
    svg.call(zoom);

    // Run the renderer. This is what draws the final graph.
    render(d3.select("svg g"), g);

    // Center the graph
    var xCenterOffset = (svg.attr("width") - g.graph().width) / 2;
    svgGroup.attr("transform", "translate(" + xCenterOffset + ", 20)");
    svg.attr("height", g.graph().height + 40);

    var yCenterOffset = (svg.attr("height") - g.graph().hegiht) / 2;
    svgGroup.attr("transform", "translate(" + yCenterOffset + ", 20)");
    svg.attr("width", g.graph().width+ 40);

    if (zoomTransform !== null) {
        console.log("applying zoom before render")
        svgGroup.attr("transform", zoomTransform);
    }


  }, [props.text])

  return <svg style={{width: "100%", backgroundColor: "white"}} id={"graph"}></svg>
}

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://huffman.ooz.ie/">
        Huffman.ooz.ie
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

/*
const footers = [
  {
    title: 'Company',
    description: ['Team', 'History', 'Contact us', 'Locations'],
  },
  {
    title: 'Features',
    description: [
      'Cool stuff',
      'Random feature',
      'Team feature',
      'Developer stuff',
      'Another one',
    ],
  },
  {
    title: 'Resources',
    description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
  },
  {
    title: 'Legal',
    description: ['Privacy policy', 'Terms of use'],
  },
];
*/


export default function Home() {

  const commands = [
    {
      command: "*",
      callback: (dictated) => {
        setText(dictated);
        setListeningText("");
      }
    }
  ];

  const {
    transcript,
    listening,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition({ commands });

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const suppliedText = params.get('text');
  const [open, setOpen] = useState(false);
  const [listeningText, setListeningText] = useState("");

  const handleOpen = () => setOpen(true);
  const [text, setText] = useState( suppliedText ? suppliedText : getRandomQuote());

  const handleTextChange = (event) => {
      setText(event.target.value);
  };

  const handleListen = (event) => {
      SpeechRecognition.startListening();
      setListeningText("I'm listening...")
  };



  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
              <Link
                variant="button"
                color="text.primary"
                href="/"
                sx={{ my: 1, mx: 1.5 }}
              >
              <img src="/logo.png"></img>
            </Link>
          </Typography>
          <Typography variant="h6" color="red" noWrap sx={{ flexGrow: 1 }}>
            {listeningText}
          </Typography>
        </Toolbar>
      </AppBar>
      {/* Hero unit */}

      <Container maxWidth={"md"} component="main" sx={{ pt: 1, pb: 1 }}>
        <Typography
          component="h2"
          variant="h5"
          align="center"
          color="text.primary"
          gutterBottom
        >
          1. Enter Text
          &nbsp;
          <Button onClick={handleListen}>  
            <MicIcon fontSize={"large"}/>
          </Button>

        </Typography>
        <Typography
          component="h2"
          variant="h4"
          align="center"
          color="text.primary"
          gutterBottom
        >
          <TextField id="demo-helper-text-misaligned-no-helper" label="Text To Encode"
            fullWidth={true}
            multiline rows={2}
            inputProps={{style: {fontSize: 24}}}
            value={text}
            onChange={handleTextChange}
           />
        </Typography>
      </Container>

      <Container maxWidth={"xl"} component="main" sx={{ pt: 0, pb: 0 }}>
        <Typography
          component="h2"
          variant="h5"
          align="center"
          color="text.primary"
          gutterBottom
        >
          2. Analyze the Tree <a href="https://en.wikipedia.org/wiki/Huffman_coding" target="_blank" > (How?)</a> &nbsp;&nbsp;&nbsp;&nbsp;   
          3.<Button onClick={handleOpen}>  Get permalink <LinkIcon fontSize={"large"}/></Button>
          <PermalinkModal open={open} setOpen={setOpen} text={text} />
        </Typography>
        <Box display="flex"
            justifyContent="center"
            alignItems="center"
          >
          <Graph text={text} style={{}} />
        </Box>
      </Container>

      {/* Footer */}

      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        <Copyright sx={{ mt: 5 }} />
      </Container>
      {/* End footer */}
    </React.Fragment>
  );
}
