
export default class Node {
  constructor() {
    this.L = null;
    this.R = null;
    this.parent = null;
    this._value = null;
    this._priority = null;
    this.code = "";
  }

  getValue() {
    if (this._value != null) {
      return this._value;
    }
    return this.L.get_value() + this.R.value();
  }

  getPriority() {
    if (this._priority != null) {
      return this._priority;
    }
    return this.L.getPriority() + this.R.getPriority();
  }

  isLeaf() {
    return (this.L === null) && (this.R === null)
  }

  getName() {
    if (this.isLeaf()) {
      return this._value;
    } else {
      var name = "";
      if (this.L !== null) {
        name += this.L.getName();
      }
      if (this.R !== null) {
        name += this.R.getName();
      }
    }
    return name;
  }

  getCode() {
    if (this.parent === null) {
      return ""
    }
    return this.parent.getCode() + this.code;
  }


}

export function getFrequency(text) {
  var freq = {};
  for (var i = 0; i < text.length; i++) {
    var char = text[i];
    if (char === " ") {
      char = "SPACE"
    }
    if (char in freq) {
      freq[char] += 1;
    } else {
      freq[char] = 1;
    }
  }

  return freq;
}

export const getRandomQuote = () => {
  const quotes = [
    "Every moment is a fresh beginning",
    "Die with memories, not dreams",
    "Aspire to inspire before we expire",
    "Everything you can imagine is real - Picasso",
    "Simplicity is the ultimate sophistication - Da Vinci",
    "Whatever you do, do it well.",
    "What we think, we become.",
    "All limitations are self-imposed",
    "Yesterday you said tomorrow. Just do it.",
    "Be so good they can’t ignore you.",
  ];
  const randomInt = (max) => {
    return Math.floor(Math.random() * max);
  }
  return quotes[randomInt(quotes.length)];
};

export function huffmanEncode(frequency) {
  var queue = [];

  for (const [key, value] of Object.entries(frequency)) {
    const node = new Node();
    node._value = key;
    node._priority = value;
    queue.push(node);
  }

  while (queue.length > 1) {
    // sort queue by _priority
    queue.sort( (a, b) => {
      return a.getPriority() - b.getPriority();
    })
    // shift first 2 elements
    const left = queue.shift();
    const right = queue.shift();
    const newNode = new Node();
    left.parent = newNode;
    right.parent = newNode;
    left.code = "0"
    right.code = "1"
    newNode.L = left;
    newNode.R = right;
    queue.unshift(newNode);
    // create a common node out of them, place back on the queue
  }

  return queue[0];
}


