import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid }from '@mui/material';
import { Button }from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import Link from '@mui/material/Link';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: "100vw",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

/* eslint react/prop-types: 0 */

export default function PermalinkModal(props) {
  const handleClose = () => props.setOpen(false);
  const encodedText = encodeURI(props.text).replaceAll('%20', '+');
  const permalink = "https://huffman.ooz.ie/?text="+encodedText;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(permalink);
    props.setOpen(false);
  };

  return (
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Link to this tree
          </Typography>
          <Grid spacing={3} container direction={"row"}>
            <Grid item>
              <Button style={{height: "100%"}} onClick={copyToClipboard}>
                <ContentCopyIcon />
              </Button>
            </Grid>
            <Grid item style={{width: "80%", overflowWrap: "break-word" }}>
              <Link  id="selection" color="inherit" href={permalink} >
                  {permalink}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Modal>
  );
}
